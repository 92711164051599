/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
        $(".navbar-toggler").on("click", function (e) {
          $("body").toggleClass("navbar-collapse-show");
        });
        $(".navbar .menu-item-126 > a").on("click", function (e) {
          location.href = this.href;
        });

        $('[data-toggle="popover"]').popover({
          trigger: "hover focus",
          placement: "bottom",
        });

        function resizeBlankImages() {
          $("img.lazy").each(function () {
            var originalWidth = $(this).attr("data-width");
            var originalHeight = $(this).attr("data-height");
            var ratioH = originalWidth / originalHeight;
            var width = $(".item").width();
            var height = width / ratioH;
            $(this).css("height", height);
            $(this).parent().css("min-height", height);
          });
        }
        $(window).on("resize", function (e) {
          resizeBlankImages();
        });
        $("img.lazy").each(function (index) {
          var originalWidth = $(this).attr("data-width");
          var originalHeight = $(this).attr("data-height");
          var ratioH = originalWidth / originalHeight;
          var width = $(".item").width();
          var height = width / ratioH;
          $(this).css("height", height);
          $(this).parent().css("min-height", height);
        });
        $("img.lazy, div.lazy").lazyload({
          effect: "fadeIn",
          failure_limit: Math.max($("img.lazy").length - 1, 0),
        });
        var loader =
          '<div class="ajax-loader"><svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/></path></g></g></svg></div>';

        var ajaxmodal = $("#ajaxmodal");

        $(document).on("click", ".post-link", function (e) {
          var post_link = $(this).attr("data-url");
          ajaxmodal.attr("data-permalink", post_link);
        });

        ajaxmodal.on("show.bs.modal", function (e) {
          window.history.pushState("forward", null, "#ajaxmodal");
        });
        $(window).on("popstate", function () {
          ajaxmodal.modal("hide");
        });
        ajaxmodal.on("shown.bs.modal", function (e) {
          var permalink = $(this).attr("data-permalink") + " #ajaxsection";
          ajaxmodal.find(".ajaxcontent").html(loader);
          ajaxmodal.find(".ajaxcontent").load(permalink);
          return false;
        });
        ajaxmodal.on("hide.bs.modal", function (e) {
          ajaxmodal.attr("data-permalink", "");
          ajaxmodal.find(".ajaxcontent").html("");
          history.pushState("", document.title, window.location.pathname);
        });
        $(document).ajaxComplete(function () {
          $(".modal div.lazy,.modal img.lazy").lazyload({
            effect: "fadeIn",
          });
        });

        $(".dropdown-submenu a.dropdown-toggle").on(
          "mouseenter mouseleave",
          function (e) {
            var _d = $(e.target).closest(".dropdown-submenu");
            _d.addClass("show");
            setTimeout(function () {
              _d[_d.is(":hover") ? "addClass" : "removeClass"]("show");
            }, 300);
          }
        );
        $(document).ready(function () {
          if ($(window).width() <= 992) {
            $(".dropdown-submenu a.dropdown-toggle").on("click", function (e) {
              $(this).next("ul").toggle();
              e.stopPropagation();
              e.preventDefault();
            });
          }
          $(window).on("resize", function (e) {
            if ($(window).width() <= 992) {
              $(".dropdown-submenu a.dropdown-toggle").on(
                "click",
                function (e) {
                  $(this).next("ul").toggle();
                  e.stopPropagation();
                  e.preventDefault();
                }
              );
            }
          });
        });
        var newsSwiper = new Swiper(".swiper-news", {
          slidesPerView: 1,
          spaceBetween: 0,
          on: {
            slideChangeTransitionEnd: function () {
              $(window).trigger("scroll");
            },
          },
          slidesPerGroup: 1,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          breakpoints: {
            576: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            992: {
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
          },
        });
        newsSwiper.emit("slideChangeTransitionEnd");
        // init Isotope
        var $grid = $(".grid").isotope({
          itemSelector: "none",
          masonry: {
            columnWidth: ".item",
          },
          visibleStyle: { transform: "translateY(0)", opacity: 1 },
          hiddenStyle: { transform: "translateY(100px)", opacity: 0 },
        });
        $grid.imagesLoaded(function () {
          $grid.removeClass("are-images-unloaded");
          $grid.isotope("option", { itemSelector: ".item" });
          var $items = $grid.find(".item");
          $grid.isotope("appended", $items);
        });
        // init Isotope + infinite-scroll
        var $gridInfinite = $(".grid-infinite").isotope({
          itemSelector: "none",
          masonry: {
            columnWidth: ".item",
          },
          visibleStyle: { transform: "translateY(0)", opacity: 1 },
          hiddenStyle: { transform: "translateY(100px)", opacity: 0 },
        });
        $gridInfinite.imagesLoaded(function () {
          $gridInfinite.removeClass("are-images-unloaded");
          $gridInfinite.isotope("option", { itemSelector: ".item" });
          var $items = $gridInfinite.find(".item");
          $gridInfinite.isotope("appended", $items);
        });
        $gridInfinite.on(
          "append.infiniteScroll",
          function (event, body, path, items, response) {
            $("img.lazy.not-loaded , div.lazy.not-loaded")
              .removeClass("not-loaded")
              .lazyload({
                effect: "fadeIn",
                failure_limit: Math.max($("img.lazy").length - 1, 0),
              });
            $(".load-more").removeClass("loading");
          }
        );
        var iso = $gridInfinite.data("isotope");
        $gridInfinite.infiniteScroll({
          path: ".nav-previous a",
          append: ".item",
          outlayer: iso,
          status: ".page-load-status",
          hideNav: ".navigation",
          button: ".load-more",
          scrollThreshold: false,
        });
        $(".load-more").on("click", function () {
          $(this).addClass("loading");
        });

        $(".infinite-scroll-last").on("click", function (e) {
          $("html, body").animate({ scrollTop: 0 }, 500);
        });
        document
          .getElementById("search-input")
          .addEventListener("keypress", function (e) {
            if (e.key === "Enter") {
              e.preventDefault();
              document.getElementById("see-all-results-link").click();
            }
          });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
        $("#more").on("click", function (event) {
          // Make sure this.hash has a value before overriding default behavior
          if (this.hash !== "") {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $("html, body").animate(
              {
                scrollTop: $(hash).offset().top,
              },
              800,
              function () {
                // Add hash (#) to URL when done scrolling (default click behavior)
                window.location.hash = hash;
              }
            );
          } // End if
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // Glossary page
    post_type_archive_glossary: {
      init: function () {
        var sections = $("#alpha-glossary").find("[id]");
        var pagination_links = $(".pagination").find("a");

        pagination_links.on("click", function (e) {
          e.preventDefault();
          var target_id = $(this).attr("href");
          var target_offset = $(target_id).offset().top - 150;

          $("html, body").animate(
            {
              scrollTop: target_offset,
            },
            500
          );
          setTimeout(function () {
            $(this)
              .parent()
              .addClass("active")
              .siblings()
              .removeClass("active");
          }, 500);
        });

        $(window).scroll(function () {
          var current_scroll = $(this).scrollTop();
          var current_section = sections
            .filter(function () {
              return $(this).offset().top - 151 <= current_scroll;
            })
            .last();

          var active_link = pagination_links.filter(
            '[href="#' + current_section.attr("id") + '"]'
          );
          active_link
            .parent()
            .addClass("active")
            .siblings()
            .removeClass("active");
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
